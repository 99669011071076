export function genTimeBasedId(): string {
    const randomArray = new Uint8Array(new Float64Array([Math.random()]).buffer,0,6)
    const randomPart = `${randomArray[0].toString(16).padStart(2, '0')}${randomArray[1].toString(16).padStart(2, '0')}${randomArray[2].toString(16).padStart(2, '0')}${randomArray[3].toString(16).padStart(2, '0')}${randomArray[4].toString(16).padStart(2, '0')}${randomArray[5].toString(16).padStart(2, '0')}`
    return `${new Date().getTime()}-${randomPart}`
}

export function genShortRandomId(): string {
    const randomArray = new Uint8Array(new Float64Array([Math.random()]).buffer,0,6)
    return `${randomArray[0].toString(16).padStart(2, '0')}${randomArray[1].toString(16).padStart(2, '0')}${randomArray[2].toString(16).padStart(2, '0')}${randomArray[3].toString(16).padStart(2, '0')}${randomArray[4].toString(16).padStart(2, '0')}${randomArray[5].toString(16).padStart(2, '0')}`
}
