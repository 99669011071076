/// <reference types="vite/client" />
// import { v4 as genUuid } from 'uuid'
import { genTimeBasedId } from './common/utils/id'
import { flatPromise, FlatPromise } from './common/async/flatPromise'
import { createEventStream } from './common/utils/eventStream'
import { sleep } from './common/async/sleep'
import type { DeployServerAPI } from 'backend/deployServerAPI'

function serverWSConnectionURL() {
    if(import.meta.env.VITE_VM_PANEL === 'true') {
        const hostParts = location.host.split('.')
        const [prefix] = hostParts[0].split('-')
        return `wss://${prefix}-server.${hostParts.slice(1).join('.')}`
    } else {
        if (location.hostname === 'localhost')
            return `ws://localhost:8070/server/`
        return `wss://${location.hostname}/server/`
    }
}

export function deployKeepConnectionToServer(onConnected: (serverConnection: DeployServerAPI) => void): { close: () => void } {

    let stopped = false
    let socket: WebSocket
    const subscriptionListeners: { [key: string]: (data: any) => void } = {}
    const results: { [key: string]: FlatPromise<any> } = {}
    const callstacks: { [key: string]: string } = {}
    // const results = asyncMap<string, FlatPromise<any>>()
    const messageQueue: string[] = []
    let lastConnectCall = 0

    function connect() {
        lastConnectCall = new Date().getTime()
        socket = new WebSocket(serverWSConnectionURL())

        socket.addEventListener('close', async () => {
            if(new Date().getTime() - lastConnectCall < 1000) {
                await sleep(1000)
            }
            if(!stopped) {
                connect()
            }
        })

        socket.addEventListener('open', () => {
            while(messageQueue.length) {
                socket.send(messageQueue.shift())
            }
            onConnected(new Proxy({}, {
                get(target, method) {
                    if(method === 'then' || typeof method === "symbol") {
                        return undefined
                    } else {
                        return (...args: any[]) => {
                            const id = genTimeBasedId()
                            results[id] = flatPromise()
                            callstacks[id] = new Error().stack
                            const resultPromise = results[id].getPromise()
                            if(socket.readyState === WebSocket.OPEN) {
                                socket.send(JSON.stringify({
                                    id,
                                    method,
                                    args
                                }))
                            } else {
                                messageQueue.push(JSON.stringify({
                                    id,
                                    method,
                                    args
                                }))
                            }
                            return resultPromise
                        }
                    }
                }
            }) as DeployServerAPI)
        })

        socket.addEventListener('message', (msg) => {
            const data = JSON.parse(msg.data)

            if(data?.type === "subscription") {
                const subscriptionId = data['result']
                const eventStream = createEventStream((emit) => {
                    subscriptionListeners[subscriptionId] = emit
                }, () => {
                    if(socket.readyState === WebSocket.OPEN) {
                        socket.send(JSON.stringify({
                            id: 'unsubscribe',
                            args: [subscriptionId]
                        }))
                    } else {
                        messageQueue.push(JSON.stringify({
                            id: 'unsubscribe',
                            args: [subscriptionId]
                        }))
                    }
                })
                results[data['id']].resolver(eventStream)
                delete results[data['id']]
                delete callstacks[data['id']]
            } else if(data.subscriptionId) {
                subscriptionListeners[data.subscriptionId](data['data'])
            } else if(data['error']) {
                const error = new Error(data['error']['message'])
                error.stack += '\n••••local stacktrace:•••••\n' + callstacks[data['id']]
                error.stack += '\n•••••original stacktrace:•••••\n' + data['error']['stack']
                console.error(error)
                results[data['id']].rejector(error)
                delete results[data['id']]
                delete callstacks[data['id']]
            } else {
                results[data['id']].resolver(data['result'])
                delete results[data['id']]
                delete callstacks[data['id']]
            }
        })


    }



    connect()

    return {
        close() {
            console.log('close')
            socket?.close()
            stopped = true
        }
    }
}
