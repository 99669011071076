import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export function usePromise<T>(promiseCreator: () => Promise<T>, deps: any[] = [], defaultValue?: T): [T, Dispatch<SetStateAction<T>>, () => Promise<void>, boolean] {
    const [state, setState] = useState<T>(typeof defaultValue === "undefined" ? null : defaultValue)
    const [inProgress, setInProgress] = useState(false)
    // const [error, setError] = useState<Error>(null)
    useEffect(() => {
        const promise = promiseCreator()
        if(promise) {
            setInProgress(true)
            let cancel = false

            promise.then((data) => {
                if(!cancel) {
                    setInProgress(false)
                    setState(data)
                }
            })

            return () => {
                cancel = true
            }
        }

    }, deps)

    return [state, setState, () => {
        const promise = promiseCreator()
        if(promise) {
            setInProgress(true)
            return promise.then((data) => {
                setInProgress(false)
                setState(data)
            })
        }
        return Promise.reject('Promise not exist')
    }, inProgress]
}
