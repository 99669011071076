import * as React from 'react'
import {useCallback, useState, useEffect} from 'react'
import {Button, Form, Header} from 'semantic-ui-react'
import {BotServer} from 'backend/types/BotServer'
import {goodNewBotName} from 'backend/goodNewBotName'
import {useDeployServerConnection} from '../hooks/deployServerConnection'

const DeployCreateNewBot = ({
                                botGroups,
                                botsObject,
                                onClose,
                            }: {
    botGroups: {
        _id: string
        _rev: string
        groups: string[]
    },
    botsObject: {
        [botId: string]: BotServer
    },
    onClose: () => void,
}) => {
    const serverConnection = useDeployServerConnection()
    const checkBotName = useCallback((newName: string) => {
        return goodNewBotName(botsObject, newName)
    }, [botsObject])

    const [botId, setBotId] = useState("")
    const [name, setName] = useState("")
    const [creating, setCreating] = useState(false)
    const [group, setGroup] = useState<string>(null)

    const botGroupsOptions = botGroups.groups.map((group) => {
        return {
            key: group,
            value: group,
            text: group,
        }
    })

    useEffect(() => {
        if (group === null) {
            if (botGroups.groups.length === 1) {
                setGroup(botGroups.groups[0])
            } else if (botGroups.groups.includes('Main')) {
                setGroup('Main')
            }
        }
    }, [group, botGroups.groups])

    return (
        <div style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <div style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
            }}>
                <Button onClick={() => onClose()}>
                    Cancel
                </Button>
            </div>
            <div>
                <Header>
                    New Server:
                </Header>
                <Form>
                    <Form.Group>
                        <Form.Select
                            style={{
                                width: 200,
                            }}
                            label="Group:"
                            options={botGroupsOptions}
                            value={group}
                            onChange={(e, {value}) => setGroup(value as string)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input
                            style={{
                                width: 200,
                            }}
                            error={!checkBotName(name)}
                            label="Name:"
                            value={name}
                            onChange={(e) => setName(e.target.value.trim().toUpperCase())}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input
                            style={{
                                width: 200,
                            }}
                            label="Id (может быть пустым):"
                            value={botId}
                            onChange={(e) => setBotId(e.target.value.trim())}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Button
                            style={{
                                marginTop: 20,
                            }}
                            disabled={name.length === 0 || !checkBotName(name)}
                            loading={creating}
                            onClick={async () => {
                                setCreating(true)
                                await serverConnection.newBot(group, name, botId)
                                setCreating(false)
                                onClose()
                            }}
                        >
                            Create
                        </Form.Button>
                    </Form.Group>
                </Form>
            </div>
        </div>
    )
}

export default DeployCreateNewBot
