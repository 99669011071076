export type FlatPromise<T> = {
    getPromise: () => Promise<T>,
    state: "pending" | "resolved" | "rejected"
    result?: T,
    error?: Error,
    resolver: (data: T) => void,
    rejector: (err: Error) => void
    _promise?: Promise<T>
}

function cleanStacktrace(stacktrace: string) {
    const lines = stacktrace.split('\n').slice(2)
    let nodeInternalLineIndex = -1
    for(let i = 0; i < lines.length; i += 1) {
        const line = lines[i]
        if(line.includes('node:internal')) {
            nodeInternalLineIndex = i
            break
        }
    }

    if(nodeInternalLineIndex === -1) {
        return lines.join('\n')
    } else {
        return lines.slice(0, nodeInternalLineIndex).join('\n')
    }
}

export function flatPromise<T>() {
    const flat: FlatPromise<T> = {
        _promise: null,
        getPromise() {
            // console.log(new Error().stack.split(''))
            const getPromiseStack = new Error().stack

            if(flat._promise === null) {
                if(flat.state === "pending") {
                    flat._promise = new Promise((resolve, reject) => {
                        flat.resolver = (data) => {
                            if(flat.state === "pending") {
                                flat.result = data
                                flat.state = "resolved"
                                resolve(data)
                            }
                        }
                        flat.rejector = (err) => {
                            if(flat.state === "pending") {
                                flat.error = err
                                flat.state = "rejected"
                                if(err && err.stack) {
                                    err.stack += '\n-----Async Stacktrace:-----\n' + cleanStacktrace(getPromiseStack)
                                }
                                reject(err)
                            }
                        }
                    })
                } else if(flat.state === "resolved") {
                    flat._promise = Promise.resolve(flat.result)
                    flat.resolver = () => {}
                    flat.rejector = () => {}
                } else if(flat.state === "rejected") {
                    if(flat.error && flat.error.stack) {
                        flat.error.stack += '\n-----Async Stacktrace:-----\n' + cleanStacktrace(getPromiseStack)
                    }
                    flat._promise = Promise.reject(flat.error)
                    flat.resolver = () => {}
                    flat.rejector = () => {}
                }
            }

            return flat._promise
        },
        state: "pending",
        resolver: (data) => {
            if(flat._promise !== null) {
                // promise with new resolver created
                flat.resolver(data)
            } else {
                if(flat.state === "pending") {
                    flat.result = data
                    flat.state = "resolved"
                }
            }
        },
        rejector: (error) => {
            if(flat._promise !== null) {
                // promise with new rejector created
                flat.rejector(error)
            } else {
                if(flat.state === "pending") {
                    flat.error = error
                    flat.state = "rejected"
                }
            }
        },
    }

    return flat
}
