import { useState } from "react"
import { EventStream } from "../common/types/EventStream"
import { useSubscription } from "../vite-code/hooks/subscription"
import { useDeployServerConnection } from "./deployServerConnection"
import type { DeployServerAPI } from 'backend/deployServerAPI'


export function useDeployValueStream<T>(streamCreator: (serverConnection: DeployServerAPI) => Promise<EventStream<T>>, deps: any[] = [], defaultValue?: T) {
    const serverConnection = useDeployServerConnection()
    const [value, setValue] = useState(defaultValue)

    useSubscription(() => streamCreator(serverConnection), (newValue) => {
        setValue(newValue)
    }, [...deps, serverConnection])

    return value
}
