import { EventStream } from "common/types/EventStream"
import { useEffect, useRef } from "react"

export function useSubscription<T>(subscriptionCreator: () => Promise<EventStream<T>>, listener: (data: T) => void, deps: any[]) {
    const state = useRef({ listener: null })
    state.current.listener = listener
    useEffect(() => {
        let subscription: EventStream<T>
        const subscriptionPromise = subscriptionCreator()
        let unsubscribe = false
        if(subscriptionPromise) {
            subscriptionPromise.then((newSubscription) => {
                subscription = newSubscription
                if(unsubscribe) {
                    newSubscription.unsubscribe()
                } else {
                    newSubscription.listen((data) => {
                        state.current.listener(data)
                    })
                }
            })
            return () => {
                unsubscribe = true
                subscription?.unsubscribe()
            }
        }
    }, deps)
}