export function withTimeout<T>(timeout: number, promise: Promise<T>): Promise<T> {
    const timeoutError = new Error(`Timeout expired ${timeout}ms`)
    return new Promise((resolve, reject) => {
        const timeoutHandler = setTimeout(() => {
            reject(timeoutError)
        }, timeout)

        promise
            .then((data) => {
                clearTimeout(timeoutHandler)
                resolve(data)
            })
            .catch((err: Error) => {
                clearTimeout(timeoutHandler)
                reject(err)
            })
    })
}
