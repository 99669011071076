import React, {createContext, useContext, useEffect, useState, ReactNode, useMemo} from 'react'
import Keycloak from 'keycloak-js'

const KeycloakContext = createContext<{
    keycloak: Keycloak | null
    initialized: boolean
} | null>(null)

interface KeycloakProviderProps {
    children: ReactNode
    client: Keycloak
}

export const KeycloakProvider: React.FC<KeycloakProviderProps> = ({children, client}) => {
    const [keycloak, setKeycloak] = useState<Keycloak | null>(null)
    const [initialized, setInitialized] = useState<boolean>(false)

    useEffect(() => {
        // Инициализация Keycloak
        client.init({onLoad: 'login-required'})
            .then(authenticated => {
                setKeycloak(client)
                setInitialized(true)
                if (!authenticated) {
                    client.login()
                }
            })
            .catch(error => {
                console.error('Failed to initialize Keycloak', error)
                setInitialized(true) // Устанавливаем инициализацию в true, чтобы избежать бесконечного ожидания
            })
    }, [client])

    const contextValue = useMemo(() => ({keycloak, initialized}), [keycloak, initialized])

    return (
        <KeycloakContext.Provider value={contextValue}>
            {!initialized && (<p>Загрузка...</p>)}
            {initialized &&
            keycloak.hasRealmRole('web-access-deploy-panel') ? (children) :
                (<p>Недостаточно прав, обратитесь к Admin</p>)}
        </KeycloakContext.Provider>
    )
}

export const useKeycloak = () => {
    const context = useContext(KeycloakContext)
    if (!context) {
        throw new Error('useKeycloak must be used within a KeycloakProvider')
    }
    return context
}

export const useHasRole = (role: string) => {
    const {keycloak} = useKeycloak();

    if (!keycloak || !keycloak.authenticated) {
        return false; // Если пользователь не аутентифицирован, возвращаем false
    }

    return keycloak.hasRealmRole(role); // Проверяем наличие роли
};
