import * as React from 'react'
import { useState } from 'react'
import { Button, Input } from 'semantic-ui-react'
import { BotServer } from 'backend/types/BotServer'
import { useDeployServerConnection } from '../hooks/deployServerConnection'
import { ReactState } from '../vite-code/types/ReactState'


const DeployGroups = ({ botGroups, botsObject, groupFilterState, updateBotGroups }: {
    botGroups: {
        _id: string
        _rev: string
        groups: string[]
    },
    botsObject: { [botId: string]: BotServer },
    groupFilterState: ReactState<string>,
    updateBotGroups: () => void,
}) => {
    const serverConnection = useDeployServerConnection()

    const [groupFilter, setGroupFilter] = groupFilterState
    const [newGroupName, setNewGroupName] = useState("")

    const countGroupInstances = (groupName: string) => {
        return Object.values(botsObject).filter((server) => server.group === groupName).length
    }

    return (
        <div style={{
            display: 'flex',
            marginLeft: 20,
            flexWrap: "wrap",
        }}>
            <Button color={groupFilter === null ? "blue" : undefined} onClick={() => setGroupFilter(null)}>
                All
            </Button>
            {botGroups.groups.map((groupName) => {
                return (
                    <div
                        key={groupName}
                        style={{
                            display: "flex"
                        }}
                    >
                        <Button
                            key={groupName}
                            onClick={() => setGroupFilter(groupName)}
                            color={groupFilter === groupName ? "blue" : undefined}
                        >
                            {groupName}
                        </Button>
                        {
                            countGroupInstances(groupName) === 0 && (
                                <Button
                                    icon="close"
                                    onClick={async () => {
                                        await serverConnection.updateBotGroups({
                                            ...botGroups,
                                            groups: botGroups.groups.filter((name) => name !== groupName)
                                        })
                                        updateBotGroups()
                                    }}
                                />
                            )
                        }
                    </div>
                )
            })}
            <Input
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value.trim())}
            />
            <Button
                style={{
                    marginLeft: 5,
                }}
                icon="plus"
                disabled={!newGroupName}
                onClick={async () => {
                    await serverConnection.updateBotGroups({
                        ...botGroups,
                        groups: [
                            ...botGroups.groups,
                            newGroupName
                        ]
                    })
                    setNewGroupName("")
                    updateBotGroups()
                }}
            />
        </div>
    )
}

export default DeployGroups
