import {genTimeBasedId} from './id'
import {EventStream} from '../types/EventStream'

const EVENT_STREAM_TYPE = "54206922-016c-4f3a-8eec-43e676a39c1c"

export function isEventStream(data: any) {
    return typeof data === "object" && data?.type === EVENT_STREAM_TYPE
}

export function createEventStream<T>(onSubscribed: (emit: (data: T) => void) => void, onUnsubscribed: (emit: (data: T) => void) => void): {
    type: string
} & EventStream<T> {
    let listener: (data: T) => void
    return {
        type: EVENT_STREAM_TYPE,
        listen(onData: (data: T) => void) {
            listener = onData
            onSubscribed(onData)
        },
        unsubscribe() {
            onUnsubscribed(listener)
        },
    }
}

export function stateStream<T>(dataInitializer: () => Promise<T> | T) {
    let initialized = false
    let currentData: T
    const listeners = new Map<string, (data: T) => void>()

    return {
        async eventStream() {
            if (!initialized) {
                const initialData = await dataInitializer()
                if (!initialized) {
                    currentData = initialData
                }
                initialized = true
            }

            const listenerId = genTimeBasedId()

            return createEventStream<T>((emit) => {
                emit(currentData)
                listeners.set(listenerId, emit)
            }, () => {
                listeners.delete(listenerId)
            })
        },
        update(data: T) {
            listeners.forEach((listener) => listener(data))
            currentData = data
            initialized = true
        }
    }
}
