import * as React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import {useEffect, useState} from 'react'
import DeployApp from './components/DeployApp'
import {deployKeepConnectionToServer} from './connectToServer'
import {DeployServerConnection} from './hooks/deployServerConnection'
import 'semantic-ui-css/semantic.min.css'
import 'xterm/css/xterm.css'
import {BotDomainSuffixContext} from './hooks/domainSuffix'
import {usePromise} from './hooks/promise'
import type {DeployServerAPI} from 'backend/deployServerAPI'
import {ZTNetworkContext} from './hooks/ztNetwork'
import {ZTDeployAppIdContext} from './hooks/ztDeployAppId'
import Keycloak from 'keycloak-js';
import {KeycloakAuth} from './components/KeycloakAuth';
import {KeycloakProvider} from './components/KeycloakProvider';

if (location.pathname === "/dev") {
    if (!localStorage.getItem('developer')) {
        localStorage.setItem('developer', 'true')
    }
}

const App = () => {
    const [deployServerConnection, setDeployServerConnection] = useState<DeployServerAPI>(null)
    const [botDomainSuffix] = usePromise(() => deployServerConnection && deployServerConnection.botDomainSuffix(), [deployServerConnection], null)
    const [ztNetwork] = usePromise(() => deployServerConnection && deployServerConnection.hostZTNetworkId(), [deployServerConnection], null)
    const [ztDeployAppId] = usePromise(() => deployServerConnection && deployServerConnection.deployAppZTId(), [deployServerConnection], null)
    const keycloakSetting = {
        url: 'https://keycloak.best-gaming.top',
        realm: 'best-gaming',
        clientId: 'deploy.best-gaming.top'
    };
    const authInstance = new Keycloak(keycloakSetting)

    useEffect(() => {
        const {close} = deployKeepConnectionToServer((connection) => {
            setDeployServerConnection(connection)
        })

        return close
    }, [])

    if (deployServerConnection === null || ztNetwork === null || ztDeployAppId === null || botDomainSuffix === null) {
        return <div/>
    }

    return (
        <KeycloakProvider client={authInstance}>
            <DeployServerConnection.Provider value={deployServerConnection}>
                <BotDomainSuffixContext.Provider value={botDomainSuffix}>
                    <ZTNetworkContext.Provider value={ztNetwork}>
                        <ZTDeployAppIdContext.Provider value={ztDeployAppId}>
                            <DeployApp/>
                        </ZTDeployAppIdContext.Provider>
                    </ZTNetworkContext.Provider>
                </BotDomainSuffixContext.Provider>
            </DeployServerConnection.Provider>
        </KeycloakProvider>
    )
}


const root = ReactDOMClient.createRoot(document.getElementById('root'))

root.render(<App/>)

if (import.meta['hot']) {
    import.meta['hot'].accept()
}
