import * as React from 'react'
import {useEffect, useRef, useState} from 'react'
import {useDeployServerConnection} from '../hooks/deployServerConnection'
import {useDeployValueStream} from '../hooks/deployValueStream'
import DeployBotList from '../components/DeployBotList'
import TerminalLogStreams from '../vite-code/components/TerminalLogStreams'
import {useSubscription} from '../vite-code/hooks/subscription'
import {useLocalStorageState} from '../vite-code/hooks/localStorageState'
import DeployGroups from '../components/DeployGroups'
import {usePromise} from '../vite-code/hooks/promise'
import {Button, Input} from 'semantic-ui-react'
import DeployCreateNewBot from '../components/DeployCreateNewBot'
import {useZTDeployAppId} from '../hooks/ztDeployAppId'
import {useZTNetwork} from '../hooks/ztNetwork'
import {zerotier6PlaneAddress} from '../common/utils/zerotier6PlaneAddress'
import {BotServer} from 'backend/types/BotServer'
import {withTimeout} from '../common/async/withTimeout'
import {useBotDomainSuffix} from '../hooks/domainSuffix'
import {useKeycloak} from './KeycloakProvider';


async function loadBotState(bot: BotServer, botDomainSuffix: string): Promise<"stopped" | "started" | "paused" | "error" | null> {
    try {
        const response = await withTimeout(7 * 1000, fetch(`https://${bot.name}${botDomainSuffix}/server/bot-state`, {
            redirect: "follow",
        }))
        return await response.json()
    } catch (err) {
        return null
    }
}

const DeployApp = () => {
    const ztNetwork = useZTNetwork()
    const deployAppZTId = useZTDeployAppId()
    const serverConnection = useDeployServerConnection()
    const botsObject = useDeployValueStream((serverConnection) => serverConnection.botsStream(), [], {})
    const hostCounters = useDeployValueStream((serverConnection) => serverConnection.hostSlotsCountersStream(), [], null)
    const [botGroups, , updateBotGroups] = usePromise(() => serverConnection.botGroups(), [], null)
    const [createNewBot, setCreateNewBot] = useState(false)
    const [showBulkUpdate, setShowBulkUpdate] = useState(false)
    const botDomainSuffix = useBotDomainSuffix()
    const {keycloak, initialized} = useKeycloak();
    const [revision, setRevision] = useState<string>('')

    useEffect(() => {
        const link = document.createElement('link')
        link.rel = 'preconnect'
        link.href = `http://[${zerotier6PlaneAddress(ztNetwork, deployAppZTId)}]:1681`
        document.head.appendChild(link)
    }, [])

    const updateListener = useRef((update: {
        streamId: string
        type: "stdout"
        base64Data: string
    } | {
        streamId: string
        type: "stderr"
        base64Data: string
    } | {
        streamId: string
        type: "dispose"
    }) => {
    })

    const groupFilterState = useLocalStorageState<string>("BotServersList.groupFilter", null)
    const [groupFilter] = groupFilterState

    useSubscription(() => serverConnection.logUpdatesStream(), (data) => {
        updateListener.current(data)
    }, [serverConnection])

    if (hostCounters === null || botGroups === null) {
        return <div/>
    }

    if (createNewBot) {
        return (
            <DeployCreateNewBot
                botGroups={botGroups}
                botsObject={botsObject}
                onClose={() => {
                    setCreateNewBot(false)
                }}
            />
        )
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div>{keycloak?.tokenParsed?.preferred_username}</div>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: 20,
                color: '#979797',
            }}>
                {hostCounters.usedSlots} / {hostCounters.slots}
            </div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
            }}>
                <DeployGroups
                    botsObject={botsObject}
                    groupFilterState={groupFilterState}
                    botGroups={botGroups}
                    updateBotGroups={updateBotGroups}
                />
                <Button
                    style={{
                        marginLeft: 20,
                        marginRight: 20,
                    }}
                    icon="plus"
                    onClick={() => setCreateNewBot(true)}
                />
            </div>
            <div style={{
                display: 'flex',
                flexWrap: "wrap",
            }}>
                <div style={{
                    flex: 10,
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: 10,
                    }}>
                        {
                            localStorage.getItem('developer') && (
                                <>
                                    <Input
                                        value={revision}
                                        onChange={(e) => setRevision(e.target.value.trim())}
                                    />
                                    {
                                        showBulkUpdate ? (
                                            <div>
                                                <Button key="update all stopped" color='blue' onClick={async () => {
                                                    setShowBulkUpdate(false)
                                                    const botsWithStatuses = await Promise.all(Object.values(botsObject).map(async (bot) => {
                                                        return {
                                                            bot,
                                                            status: await loadBotState(bot, botDomainSuffix),
                                                        }
                                                    }))
                                                    for (const {bot, status} of botsWithStatuses) {
                                                        if (status === "stopped") {
                                                            serverConnection.updateBot(bot._id, revision)
                                                        }
                                                    }
                                                }}>
                                                    Update All Stopped
                                                </Button>
                                                <Button key="update all running" color='orange' onClick={async () => {
                                                    setShowBulkUpdate(false)
                                                    const botsWithStatuses = await Promise.all(Object.values(botsObject).map(async (bot) => {
                                                        return {
                                                            bot,
                                                            status: await loadBotState(bot, botDomainSuffix),
                                                        }
                                                    }))
                                                    for (const {bot, status} of botsWithStatuses) {
                                                        if (status === "started") {
                                                            serverConnection.updateBot(bot._id, revision)
                                                        }
                                                    }
                                                }}>
                                                    Update All Running
                                                </Button>
                                                <Button key="update all" color='pink' onClick={() => {
                                                    for (const bot of Object.values(botsObject)) {
                                                        serverConnection.updateBot(bot._id, revision)
                                                    }
                                                    setShowBulkUpdate(false)
                                                }}>
                                                    Update All
                                                </Button>
                                                <Button key="cancel" onClick={() => {
                                                    setShowBulkUpdate(false)
                                                }}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        ) : (
                                            <div>
                                                <Button key="bulk update" onClick={() => setShowBulkUpdate(true)}>Bulk
                                                    Update</Button>
                                            </div>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                    <DeployBotList
                        botGroups={botGroups}
                        botsObject={botsObject}
                        groupFilter={groupFilter}
                        canStart={hostCounters.usedSlots < hostCounters.slots}
                        revision={revision}
                    />
                </div>
                <div style={{
                    flex: 4,
                    width: 500
                }}>
                    <TerminalLogStreams
                        serverConnection={serverConnection}
                        setUpdateListener={(newUpdateListener) => {
                            updateListener.current = newUpdateListener
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default DeployApp
