import * as React from 'react'
import { useEffect, useRef } from 'react'
import * as XTerm from 'xterm'
import * as Base64ArrayBuffer from 'base64-arraybuffer'
import { useElement } from '../hooks/element'


const { Terminal } = XTerm

const TerminalLogStreams = ({ setUpdateListener, serverConnection }: {
    setUpdateListener: (onUpdate: (update: {
        streamId: string
        type: "stdout"
        base64Data: string
    } | {
        streamId: string
        type: "stderr"
        base64Data: string
    } | {
        streamId: string
        type: "dispose"
    }) => void) => void,
    serverConnection: any,
}) => {
    const [terminalsWrapperElement, terminalsWrapperElementCallback] = useElement<HTMLElement>()
    const existingTerminalsRef = useRef<{
        streams: {
            [key: string]: {
                term: XTerm.Terminal,
                wrapper: HTMLDivElement,
                element: HTMLDivElement,
            }
        }
    }>({
        streams: {}
    })
    useEffect(() => {
        if(existingTerminalsRef.current) {
            existingTerminalsRef.current.streams = {}
        }
        if(terminalsWrapperElement) {
            while(terminalsWrapperElement.firstChild) {
                terminalsWrapperElement.removeChild(terminalsWrapperElement.lastChild)
            }
        }
    }, [serverConnection])

    setUpdateListener((update) => {
        if(terminalsWrapperElement) {
            if(update.type === "stdout" || update.type === "stderr") {
                if(!existingTerminalsRef.current.streams[update.streamId]) {
                    const term = new Terminal({
                        convertEol: true,
                    })
                    const wrapper = document.createElement('div')
                    const header = document.createElement('h4')
                    const terminalElement = document.createElement('div')
                    header.setAttribute('style', "margin: 0; background: white")
                    wrapper.appendChild(header)
                    header.append(document.createTextNode(update.streamId))
                    terminalElement.setAttribute('style', 'overflow: auto; background: black;')
                    wrapper.appendChild(terminalElement)
                    terminalsWrapperElement.insertBefore(
                        wrapper,
                        terminalsWrapperElement.firstChild,
                    )
                    existingTerminalsRef.current.streams[update.streamId] = {
                        term,
                        wrapper,
                        element: terminalElement,
                    }
                    term.open(terminalElement)
                }
                const term = existingTerminalsRef.current.streams[update.streamId].term
                term.write(new Uint8Array(Base64ArrayBuffer.decode(update.base64Data)))
            } else if(update.type === "dispose") {
                const stream = existingTerminalsRef.current.streams[update.streamId]
                if(stream) {
                    stream.wrapper.remove()
                    delete existingTerminalsRef.current.streams[update.streamId]
                }
            }
        }
    })

    return (
        <div
            style={{
                maxHeight: '100vh',
            }}
            ref={terminalsWrapperElementCallback}
        />
    )
}

export default TerminalLogStreams
