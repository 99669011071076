import React from 'react'
import { useState } from "react"
import { Button } from "semantic-ui-react"

const ButtonWithConfirmation = ({ style, actionButtonStyle, disabled, confirmationText, actionText, actionColor, onAction, children }: { style?: React.CSSProperties, actionButtonStyle?: React.CSSProperties, disabled?: boolean, confirmationText: string, actionText: string, actionColor: Button['props']['color'], onAction: () => void, children: React.ReactElement | string }) => {
    const [confirmation, setConfirmation] = useState(false)

    if(confirmation) {
        return (
            <div>
                <div>
                    {confirmationText}
                </div>
                <div>
                    <Button onClick={() => setConfirmation(false)}>
                        Cancel
                    </Button>
                    <Button style={actionButtonStyle} color={actionColor} onClick={() => {
                        setConfirmation(false)
                        onAction()
                    }}>
                        {actionText}
                    </Button>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <Button style={style} disabled={disabled} onClick={() => setConfirmation(true)}>
                    {children}
                </Button>
            </div>
        )
    }

}

export default ButtonWithConfirmation
