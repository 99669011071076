import * as React from 'react'
import { zerotier6PlaneAddress } from '../common/utils/zerotier6PlaneAddress'
import { computeBotIP } from '../botIP'
import { BotServer } from 'backend/types/BotServer'
import { Button, Card, Dimmer, Form, Icon, Label, Loader, Popup, Segment, Select } from 'semantic-ui-react'
import ButtonWithConfirmation from '../vite-code/components/ButtonWithConfirmation'
import { useDeployServerConnection } from '../hooks/deployServerConnection'
import { useZTNetwork } from '../hooks/ztNetwork'
import { useState } from 'react'
import { usePromise } from '../vite-code/hooks/promise'
import { useBotDomainSuffix } from '../hooks/domainSuffix'
import { useZTDeployAppId } from '../hooks/ztDeployAppId'


const BotName = ({ bot, name, checkBotName, botVersion }: { bot: BotServer, name: string, checkBotName: (newName: string) => boolean, botVersion: {
    type: "revision"
    version: string
} | {
    type: "version"
    version: string
} | null }) => {
    const serverConnection = useDeployServerConnection()
    const [edit, setEdit] = useState(false)
    const [saving, setSaving] = useState(false)
    const [newName, setNewName] = useState(name)
    const botDomainSuffix = useBotDomainSuffix()

    if(edit) {
        if(saving) {
            return (
                <Dimmer active inverted>
                    <Loader inverted />
                </Dimmer>
            )
        } else {
            return (
                <Form onSubmit={async () => {
                    if(bot.name !== newName) {
                        if(checkBotName(newName)) {
                            setSaving(true)
                            await serverConnection.renameBot(bot._id, newName)
                            setEdit(false)
                            setSaving(false)
                        }
                    } else {
                        setEdit(false)
                    }
                }}>
                    <Form.Input
                        error={!checkBotName(newName) && bot.name !== newName}
                        value={newName}
                        onKeyDown={(e: KeyboardEvent) => {
                            if(e.key === "Escape") {
                                setNewName(name)
                                setEdit(false)
                            }
                        }}
                        onChange={(e) => setNewName(e.target.value.trim().toUpperCase())}
                    />
                </Form>
            )
        }
    } else {
        return (
            <div>
                <span onDoubleClick={() => setEdit(true)}>
                    {name}
                </span>
                {
                    botVersion !== null && localStorage.getItem('developer') === "true" && (
                        <span style={{
                            color: '#cbcbcb',
                            marginLeft: 10,
                            fontSize: '14px',
                        }}>
                            {botVersion.type === "revision" && (
                                <a href={`https://gitlab.bot-dev.top/dev/betting-bot/-/tree/${botVersion.version}`}>
                                    {'['}
                                    {botVersion.version.slice(0, 8)}
                                    {']'}
                                </a>
                            )}
                            {botVersion.type === "version" && (
                                <>
                                    {'['}
                                    {botVersion.version}
                                    {']'}
                                </>
                            )}
                        </span>
                    )
                }
            </div>
        )
    }
}

const DeployBotView = ({
    botGroups,
    bot,
    canStart,
    checkBotName,
    revision,
}: {
    botGroups: {
        _id: string
        _rev: string
        groups: string[]
    },
    bot: BotServer,
    canStart: boolean,
    checkBotName: (newName: string) => boolean,
    revision: string,
}) => {
    const ztNetwork = useZTNetwork()
    const deployAppZTId = useZTDeployAppId()
    const serverConnection = useDeployServerConnection()
    const [groupEdit, setGroupEdit] = useState(false)
    const [newGroup, setNewGroup] = useState(bot.group)
    const botDomainSuffix = useBotDomainSuffix()

    const [botVersion] = usePromise<{
        type: "revision"
        version: string
    } | {
        type: "version"
        version: string
    }>(async () => {
        if(bot.state.type === "ready") {
            try {
                const response = await fetch(`https://${bot.name}${botDomainSuffix}/server/bot-version-full`, {
                    redirect: "follow",
                })
                return await response.json()
            } catch(err) {
                return null
            }
        } else {
            return null
        }
    }, [bot.state.type], null)

    const botURL = `https://${bot.name}${botDomainSuffix}/`.toLowerCase()
    let botURLWithRedirect = botURL
    if(botVersion === null && bot.state.type === "ready" && bot.state.serverInfo.type === "host pod") {
        botURLWithRedirect = `http://[${zerotier6PlaneAddress(ztNetwork, deployAppZTId)}]:1681/redirect/${bot.state.serverInfo.hostId}/${encodeURIComponent(botURL)}`
    }

    return (
        <Card style={{
            margin: 20,
        }}>
            <Card.Content>
                <Card.Header>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <BotName
                            bot={bot}
                            name={bot.name}
                            checkBotName={checkBotName}
                            botVersion={botVersion}
                        />
                        <Label>
                            {bot.state.type.toUpperCase()}
                        </Label>
                    </div>
                </Card.Header>
                <Card.Description>
                    <div style={{
                        opacity: 0.3,
                        fontSize: 12,
                    }}>
                        {bot._id}
                    </div>
                    <div style={{
                        display: "flex",
                    }}>
                        {
                            bot.state.type === "ready" && (
                                <ButtonWithConfirmation
                                    style={{
                                        fontSize: '13px'
                                    }}
                                    confirmationText="Are you sure?"
                                    actionText="Stop the Bot"
                                    actionColor="blue"
                                    onAction={() => {
                                        serverConnection.stopBot(bot._id)
                                    }}
                                >
                                    STOP
                                </ButtonWithConfirmation>
                            )
                        }
                        {
                            localStorage.getItem('developer') && bot.state.type === "ready" && (
                                <ButtonWithConfirmation
                                    style={{
                                        fontSize: '13px'
                                    }}
                                    confirmationText="Are you sure?"
                                    actionText="Update The Server"
                                    actionColor="green"
                                    onAction={() => {
                                        serverConnection.updateBot(bot._id, revision)
                                    }}
                                >
                                    UPDATE
                                </ButtonWithConfirmation>
                            )
                        }
                        {
                            bot.state.type === "ready" && (
                                <ButtonWithConfirmation
                                    style={{
                                        fontSize: '13px'
                                    }}
                                    confirmationText="Are you sure?"
                                    actionText="Restart The Server"
                                    actionColor="blue"
                                    onAction={() => {
                                        serverConnection.restartBot(bot._id)
                                    }}
                                >
                                    RESTART
                                </ButtonWithConfirmation>
                            )
                        }
                    </div>
                    {
                        (bot.state.type === "starting" || bot.state.type === "stopping") && (
                            <div style={{
                                position: bot.state.type === "starting" ? "relative" : undefined,
                                minHeight: 40,
                            }}>
                                <Dimmer active inverted>
                                    <Loader />
                                </Dimmer>
                            </div>
                        )
                    }
                    {
                        (bot.state.type === "starting" || bot.state.type === "ready" || bot.state.type === "stopping") && bot.state.serverInfo.type === "host pod" && (
                            <div>
                                Host: {bot.state.serverInfo.hostId}
                            </div>
                        )
                    }
                    {
                        groupEdit ? (
                            <div style={{
                                display: "flex",
                            }}>
                                <div
                                    style={{
                                        width: 170,
                                    }}
                                >
                                    <Select
                                        fluid
                                        options={botGroups.groups.map((group) => {
                                            return {
                                                key: group,
                                                value: group,
                                                text: group,
                                            }
                                        })}
                                        value={newGroup}
                                        onChange={(e, { value }) => {
                                            setNewGroup(value as string)
                                        }}
                                    />
                                </div>
                                <Button
                                    style={{
                                        marginLeft: 5,
                                    }}
                                    icon="check"
                                    onClick={async () => {
                                        await serverConnection.changeBotGroup(bot._id, newGroup)
                                        setGroupEdit(false)
                                    }}
                                />
                                <Button
                                    icon="close"
                                    onClick={() => {
                                        setGroupEdit(false)
                                    }}
                                />
                            </div>
                        ) : (
                            <div onDoubleClick={() => {
                                setGroupEdit(true)
                            }}>
                                Group: {bot.group}
                            </div>
                        )
                    }
                    {
                        bot.state.type === "ready" && bot.state.serverInfo.type === "host pod" && (
                            <div
                                style={{
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    if(bot.state.type === "ready" && bot.state.serverInfo.type === "host pod") {
                                        const sshCommand = `ssh root@${zerotier6PlaneAddress(ztNetwork, bot.state.serverInfo.hostId)}`
                                        navigator.clipboard.writeText(sshCommand)
                                    }
                                }}
                            >
                                <Popup
                                    inverted
                                    hideOnScroll
                                    position="top center"
                                    content="Сopied"
                                    on="click"
                                    trigger={<Icon name='terminal' width={20} height={20} />}
                                />
                            </div>
                        )
                    }
                    <div>
                        {
                            bot.state.type === "stopped" && (
                                <ButtonWithConfirmation
                                    style={{
                                        fontSize: '13px'
                                    }}
                                    disabled={!canStart}
                                    confirmationText="Are you sure?"
                                    actionText="Start the Bot"
                                    actionColor="green"
                                    onAction={() => {
                                        serverConnection.startBot(bot._id, location.origin, revision)
                                    }}
                                >
                                    Start
                                </ButtonWithConfirmation>
                            )
                        }
                        {
                            bot.state.type === "starting" && (
                                <Button
                                    onClick={() => {
                                        serverConnection.stopBot(bot._id)
                                    }}
                                >
                                    Cancel
                                </Button>
                            )
                        }
                        {
                            bot.state.type === "ready" && (
                                <Button as="a" href={botURLWithRedirect}>
                                    BOT
                                </Button>
                            )
                        }
                        {
                            localStorage.getItem('developer') && (bot.state.type === "ready" || bot.state.type === "starting") && (bot.state.serverInfo.type === "host pod") && (
                                <Button as="a" target="_blank" href={`http://[${computeBotIP(ztNetwork, bot.state.serverInfo.hostId, bot._id)}]:5984/_utils/`}>
                                    DB
                                </Button>
                            )
                        }
                        {
                            bot.state.type === "ready" && (
                                <Button as="a" href={`https://${bot.name}${botDomainSuffix}/novnc/?autoconnect=true`}>
                                    noVNC
                                </Button>
                            )
                        }
                    </div>
                </Card.Description>
            </Card.Content>
        </Card>
    )
}

export default DeployBotView
