import { useState } from "react"
import { ReactState } from "vite-code/types/ReactState"

export function useLocalStorageState<T>(name: string, init: T | (() => T)): ReactState<T> {
    const lastState = localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : init
    const [state, setState] = useState(lastState)

    return [state, (data) => {
        if(typeof data === "function") {
            const dataTransformer: Function = data
            setState((oldData) => {
                const newData = dataTransformer(oldData)
                localStorage.setItem(name, JSON.stringify(newData))
                return newData
            })
        } else {
            localStorage.setItem(name, JSON.stringify(data))
            setState(data)
        }
    }]
}
