import { useCallback, useState } from "react"

export function useElement<T extends Node>(): [T, (node: T) => void] {
    const [element, setElement] = useState<T>()
    const elementCallback = useCallback((node) => {
        setElement(node)
    }, [])

    return [element, elementCallback]
}
