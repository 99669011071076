export function zerotier6PlanePrefix(networkId: string, memberId: string) {
    const networkFirstPart = networkId.slice(0, 8)
    const networkSecondPart = networkId.slice(8)

    const parseNHexByte = (hex: string, n: number) => {
        return Number.parseInt(hex.slice(2 * n, 2 * n + 2), 16)
    }

    const networkByte = (n: number) => {
        const byte = parseNHexByte(networkFirstPart, n)
            ^ parseNHexByte(networkSecondPart, n)

        return byte.toString(16).padStart(2, '0')
    }

    const memberByte = (n: number) => parseNHexByte(memberId, n).toString(16).padStart(2, '0')

    return `fc${networkByte(0)}:${networkByte(1)}${networkByte(2)}:${networkByte(3)}${memberByte(0)}:${memberByte(1)}${memberByte(2)}:${memberByte(3)}${memberByte(4)}`
}

export function zerotier6PlaneAddress(networkId: string, memberId: string) {
    return `${zerotier6PlanePrefix(networkId, memberId)}::1`
}
