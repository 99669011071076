import { zerotier6PlanePrefix } from "./common/utils/zerotier6PlaneAddress"

export function computeBotIP(networkId: string, hostId: string, botId: string) {
    const ztMemberPrefix = zerotier6PlanePrefix(networkId, hostId)
    const block1 = botId.slice(-12, -8) || "0"
    const block2 = botId.slice(-8, -4) || "0"
    const block3 = botId.slice(-4)

    return `${ztMemberPrefix}:${block1}:${block2}:${block3}`
}
