import * as React from 'react'
import { compareStringsWithNumbers } from '../common/string/compareStringsWithNumbers'
import DeployBotView from '../components/DeployBotView'
import { BotServer } from 'backend/types/BotServer'
import { useCallback } from 'react'
import { goodNewBotName } from 'backend/goodNewBotName'


const DeployBotList = ({
    botGroups,
    botsObject,
    groupFilter,
    canStart,
    revision,
}: {
    botGroups: {
        _id: string
        _rev: string
        groups: string[]
    },
    botsObject: { [botId: string]: BotServer },
    groupFilter: string,
    canStart: boolean,
    revision: string
}) => {
    const botsOrderedList = Object.values(botsObject)
        .sort((a, b) => compareStringsWithNumbers(a.name, b.name))
        .filter((botServer) => {
            if(groupFilter === null) {
                return true
            } else {
                return botServer.group === groupFilter
            }
        })

    const checkBotName = useCallback((newName: string) => {
        return goodNewBotName(botsObject, newName)
    }, [botsObject])


    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
                botsOrderedList.map((bot) => {
                    return (
                        <DeployBotView
                            key={bot._id}
                            botGroups={botGroups}
                            bot={bot}
                            canStart={canStart}
                            checkBotName={checkBotName}
                            revision={revision}
                        />
                    )
                })
            }
        </div>
    )
}

export default DeployBotList
