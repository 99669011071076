export function compareStringsWithNumbers(a: string, b: string) {
    type Chunk = { type: 'number' | 'string', value: string | number }
    function splitIntoChunks(str: string): Chunk[] {
        const pattern = /(\d+|\D+)/g
        const chunks = str.match(pattern)
        return chunks?.map(chunk => isNaN(Number(chunk)) ?
            { type: 'string', value: chunk } :
            { type: 'number', value: Number(chunk) }) || []
    }

    function compareChunks(a: Chunk[], b: Chunk[]): number {
        for(let i = 0; i < Math.min(a.length, b.length); i++) {
            if(a[i].type === 'number' && b[i].type === 'number') {
                if(a[i].value !== b[i].value) {
                    return (a[i].value as number) - (b[i].value as number)
                }
            } else {
                if(a[i].value !== b[i].value) {
                    return (a[i].value as string).localeCompare(b[i].value as string)
                }
            }
        }
        return a.length - b.length
    }

    return compareChunks(splitIntoChunks(a), splitIntoChunks(b))
}
